import { createApp } from 'vue';
import ElementPlus from 'element-plus'; //全局引ui官网
import 'element-plus/dist/index.css';

import App from './App.vue';
import './utils/flexible';
import vant from "vant";
import 'vant/lib/index.css';
// import './plugins/element.js'

const app = createApp(App)
app.use(vant)
app.use(ElementPlus)
app.mount('#app')
