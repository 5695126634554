<template>
  <div class="item-re row-center ro-he">
    <div class="txt-list">
      <div>{{ title }}</div>
      <div class="list-rich">{{ richTitle }}</div>
      <div class="title-txt aside">{{ text }}</div>
      <img  :src="lookImg" />
    </div>
  </div>

</template>

<script setup>
    import { onMounted } from "vue";
    import { defineProps } from 'vue';
    const props = defineProps({
      title:String, //标题
      richTitle: String, //副标题
      text:String, //文本
      lookImg: String, //左边图片
    });

    onMounted(()=>{
      console.log(props)
    })
</script>

<style scoped lang="less">
.item-re{
  position: absolute;
  width: 100%;
  top:0;
}
.row-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ro-he{
  height: 112%;
}
.txt-list{
  font-weight: 600;
  font-size: 58px;
  color: #222222;
  //margin-top: 100px;
  .list-rich{
    font-weight: 500;
    font-size: 32px;
    color: #0D69EB;
    margin: 22px 0 33px;
  }
  img{
    width: 600px;
    height: 1001px;
  }
}
.title-txt{
  font-size: 26px;
  color: #616161;
  line-height: 40px;
}
.aside{
  color: #555555;
  width: 581px;
}
</style>