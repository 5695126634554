
<template>

<!--  导航栏-->
  <div style="position:absolute;top:0;width: 100%;z-index: 999">
    <div class="nav-box" :style="{ backgroundColor: dynamicBackgroundColor }">
    <div class="row-center">
      <img  class="nav-img" src="../assets/logo.png" />
      <div class="nav-title" :style="{color:textColor}">投融桥App</div>
    </div>


      <img v-if="isIcon" class="img" src="../assets/more.png" />
      <img v-else class="img" src="../assets/more-b.png" />
    </div>
  </div>
  <div class="swiper">
  <swiper :direction="'vertical'"
          :spaceBetween="0"
          :mousewheel="true"
          :slidesPerView="'auto'"
          @slideChange="clickSwiper"
          :modules="modules">
    <swiper-slide>
      <img class="swiper-bg" src="../assets/screen1.png" />
      <div class="item-re row-center">
        <div>
          <img class="re-img" src="../assets/rich-title.png" />
          <div class="name">真 实  /  高 效  /  精 准  /  快捷</div>
          <div class="column">
            <img class="conlum-img" @click="clickApple" src="../assets/apple-dow.png">
<!--            <a v-if="isUrl" href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff;text-decoration: none;" />-->
            <img class="conlum-img" @click="clickAndi" src="../assets/android-dow.png">
          </div>
        </div>
      </div>
      <div class="pos-bot">
        <img class="up-img" :src="require('../assets/arrow-bottom.png')" />
      </div>
    </swiper-slide>

    <swiper-slide>
      <img class="swiper-bg" src="../assets/screen2.png" />
      <div class="item-re row-center ro-he">
          <div class="txt">
            <div>投融桥是一款连接投资人 与项目方的app</div>
            <div class="rich">投融资直聊 轻松对接</div>
            <div class="title-txt">通过智能匹配技术，快速、高效地连接投资人和 项目方，助您轻松实现融资目标</div>
            <img class="look-img"  src="@/assets/look.png" />
          </div>
      </div>
    </swiper-slide>

<!--    第三屏幕-->
    <swiper-slide>
      <img class="swiper-bg" src="@/assets/screen3.png" />
      <swiper-content title="在线沟通" rich-title="与投资人直聊" text="提供安全的在线聊天功能可以和投资人进行高效的沟通和交流" :look-img="look1Img">
      </swiper-content>
    </swiper-slide>
<!--    第四屏幕-->
    <swiper-slide>
      <img class="swiper-bg" src="@/assets/screen3.png" />
      <swiper-content title="智能匹配" rich-title="投资人精准匹配" text="智能匹配系统能够根据您的项目需求和投资人的偏好进行精准对接，可以大大提高融资成功的几率" :look-img="look2Img">
      </swiper-content>
    </swiper-slide>
<!--    第五屏幕-->
    <swiper-slide>
      <img class="swiper-bg" src="@/assets/screen3.png" />
      <swiper-content title="数据分析" rich-title="优化展示策略" text="提供项目展示和沟通的统计数据，帮助用户优化展示策略" :look-img="look3Img">
      </swiper-content>
    </swiper-slide>
<!--    第六屏幕-->
    <swiper-slide>
      <img class="swiper-bg" src="@/assets/screen3.png" />
      <swiper-content title="项目展示" rich-title="丰富的项目资源" text="线上平台发布项目信息，突出展示项目亮点来吸引投资人的关注" :look-img="look4Img">
      </swiper-content>
    </swiper-slide>
<!--    第七屏-->
    <swiper-slide>
      <img class="swiper-bg" src="@/assets/screen3.png" />
      <div class="item-re row-center ro-he">
        <div class="sev-top">
          <div class="column">
            <img class="sev-look" src="@/assets/title-6.png" />
            <img class="sev-look" src="@/assets/title-7.png" />
          </div>
          <div class="title-txt aside cen">听听我们的用户怎么说，他们通过投融桥app获得了哪些成功经验和帮助</div>
          <img class="sev-img"  src="@/assets/case-01.png" />
          <img  class="sev-img" src="@/assets/case-02.png" />
          <div  class="column">
            <img class="sev-pic" src="@/assets/pic1.png" />
            <img class="sev-pic" src="@/assets/pic3.png" />
          </div>
        </div>
      </div>
    </swiper-slide>

<!--    尾部-->
    <swiper-slide>
      <img class="swiper-bg" src="@/assets/bottom-bg.png" />
      <div class="item-re row-center">
        <div class="foot-top">
          <div class="row-center">
            <img  class="nav-img" src="../assets/logo.png" />
            <div class="nav-title">投融桥App</div>
          </div>
          <div class="column">
            <div>公司名称：投融桥（北京）科技有限公司 </div>
             <div>工作时间：周一至周五 9:00-18:00</div>
            <div> 企业邮箱：tourongqiaoapp@16</div>
            <div>地址：北京市复兴路2号院</div>
            <div>©2019 投融桥 版权所有 备案号11030920号-3</div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
<!--  <van-sidebar v-model="active">-->
<!--    <van-sidebar-item title="标签名称" />-->
<!--    <van-sidebar-item title="标签名称" />-->
<!--    <van-sidebar-item title="标签名称" />-->
<!--  </van-sidebar>-->
  </div>
</template>
<script setup>
    import {Swiper, SwiperSlide} from "swiper/vue";
    import swiperContent from  "../components/swiperContent.vue";
    import { Mousewheel } from 'swiper/modules';
    import 'swiper/css';
    import 'swiper/css/pagination';
    import {onMounted, ref} from "vue";
    const modules = [Mousewheel]
    const isIcon = ref(true);
    const look1Img = ref(require("@/assets/look1.png"));
    const look2Img = ref(require("@/assets/look2.png"));
    const look3Img = ref(require("@/assets/look3.png"));
    const look4Img = ref(require("@/assets/look4.png"));
    const dynamicBackgroundColor = ref('transparent');//背景颜色
    const textColor = ref('#fff');//字体颜色
    onMounted(()=>{
      console.log("111")
    })
    const clickSwiper = ((e)=>{
      if(e.activeIndex !== 0){
        dynamicBackgroundColor.value = "#fff";
        textColor.value = "#000";
        isIcon.value = false
      }else{
        dynamicBackgroundColor.value = 'transparent';//背景颜色
        textColor.value = "#fff";
        isIcon.value = true
      }
    })
    const clickApple = () =>{
      console.log("11111")
      window.location.href="https://apps.apple.com/cn/app/id1493513261"
    }
    const clickAndi = () =>{
      window.location.href="https://tourongqiao.oss-cn-beijing.aliyuncs.com/apk/trqapp.apk"
    }

</script>

<style scoped lang="less">
//轮播图

.swiper {
  height: 100vh;
}
.swiper-slide{
  position: relative;
}
.swiper-slide:nth-child(8) {
  height: 778px;
}


.row-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ro-he{
  height: 112%;
}
.pos-bot{
  position:absolute;
  bottom: 59px;
  width: 100%
}
.van-swipe-item:nth-child(8) {
  height: 500px;
}
.column{
  display: flex;
  align-items: center;
  flex-direction: column;
  div{
    font-size: 28px;
    color: #CCCFD1;
    line-height: 76px;
  }
}
.nav-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  .nav-img{
    width: 69px;
    height: 70px;
    margin-left: 252px;
  }
}
.nav-title{
  font-weight: 500;
  font-size: 36px;
  margin-left: 14px;
  color: #fff;
}
.img{
  width: 50px;
  height: 50px;
  margin-right: 32px;
}
.re-img{
  width: 529px;
  height: 238px;
  margin-top: 342px;
}
.swiper-bg{
  width: 100%;
  height: 100%;
  //background-size: cover;
  object-fit: cover;
}
.item-re{
  position: absolute;
  width: 100%;
  top:0;
}
.name{
  color: #fff;
  font-size: 34px;
  letter-spacing: 6px;
  margin-bottom: 103px;
}
.conlum-img{
  width: 292px;
  height: 101px;
  margin-bottom: 26px;
}
.up-img{
  width: 30px;height: 30px;
  margin-top: 269px;
  animation:init 2s ease-in infinite;
}
@keyframes init {
  0%, 100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(.8,0,1,1);
  }
  50% {
    transform: translateY(20%);
    animation-timing-function: cubic-bezier(0,0,.2,1);
  }
}
.txt{
  font-weight: bold;
  font-size: 50px;
  color: #222222;
  div{
    width: 590px;
    text-align: left;
    margin-left: 26px;
  }
}
.rich{
  font-size: 41px;
  margin: 39px 0 48px;
}
.title-txt{
  font-size: 26px;
  color: #616161;
  line-height: 40px;
}
.aside{
  color: #555555;
  width: 581px;
}
.cen{
   margin: 29px 85px;
 }
.look-img{
  width: 664px;
  height: 680px;
  margin-top: 73px;
}
.txt-list{
  font-weight: 600;
  font-size: 58px;
  color: #222222;
  //margin-top: 100px;
  .list-rich{
    font-weight: 500;
    font-size: 32px;
    color: #0D69EB;
    margin: 22px 0 33px;
  }
  img{
    width: 600px;
    height: 1001px;
  }
}
//.sev-top{
//  margin-top: 189px;
//}
.foot-top{
  margin-top: 88px;
}
.sev-look{
  width: 303px;
  height: 80px;
}
.sev-pic{
  width: 712px;
}
.sev-img{
  width: 366px;
  height: 345px;
}

</style>